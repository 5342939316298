<template lang="pug">
router-link.link(v-if="!disabled && accountHasAccess(name)" :to="{ name }" :target="target")
  slot
span(v-else)
  slot
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import { accountHasAccess } from "~/router/auth";

export default defineComponent({
  name: "AccessedLink",
  methods: {
    accountHasAccess,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    target: {
      type: String as PropType<'_self'|'_blank'>,
      default: '_self',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/links';

.link {
  @include link-mixin;
}
</style>
